import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import flvwLogo from './flvw_logo'
const { PDFDocument } = require('pdf-lib');


export default class PDF_Generator {
    constructor(
        name,
        abrechnung,
    ){
        this.doc = new jsPDF();
        this.name = name || 'abrechnung'
        this.abrechnung = Array.isArray(abrechnung) ? abrechnung[0] : abrechnung
        this.abrechnungen = Array.isArray(abrechnung) ? abrechnung : [abrechnung]
        this.PageNumber = 1
        this.PageCount = 1
        this.pos = {
            y: 10,
            x: 10
        }
        this.pdfBelege = []
        this.pdf = ''
    }

    addPDF(){
        this.doc.addPage()
        this.PageNumber++
        this.PageCount = 1
        this.pos = {
            y: 10,
            x: 10
        }
    }

    save(){
        this.doc.save(this.name+'.pdf');
    }

    formatIBAN(iban){
        return iban.match(/.{1,4}/g).join(' ')
    }

    async generatePDF(){
        this.pdf = await PDFDocument.create();
        for(const abrechnung of this.abrechnungen){
            this.doc = new jsPDF();
            this.abrechnung = abrechnung;
            this.person = this.abrechnung.abrechnender;
            if(this.abrechnungen.length == 1){
                this.name = 'FLVW-'+abrechnung.type+'-'+this.person.nachname+'-'+Date.now()
            }

            if(this.abrechnung?.type == 'Auslagenabrechnung'){
                const auslagenabrechnung = await this.auslagenabrechnung();
            }
            else {
                const reisekostenabrechnung = await this.reisekostenabrechnung();
            }

            const abrechnungPdfDoc = await PDFDocument.load(this.doc.output('dataurlstring'));
            const abrechnungPdfPages = await this.pdf.copyPages(abrechnungPdfDoc, abrechnungPdfDoc.getPageIndices());
            abrechnungPdfPages.forEach(page => {
                this.pdf.addPage(page);
            })

            for(const pdfFile of this.pdfBelege){
                // Load each PDF file
                const pdfBytes = await fetch(pdfFile).then((response) => response.arrayBuffer());
            
                // Add each PDF file to the merged PDF document
                const pdfDoc = await PDFDocument.load(pdfBytes);
                const pages = await this.pdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                pages.forEach((page) => {
                    this.pdf.addPage(page);
                });
            }
        }

        // Serialize the merged PDF to a Uint8Array
        const mergedPdfBytes = await this.pdf.save();
        // Create a Blob from the Uint8Array
        const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        
        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(mergedPdfBlob);
        downloadLink.download = this.name+'.pdf'; // Specify the desired filename
        downloadLink.style.display = 'none';

        // Trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        // // Save
        // var filename = this.abrechnung.type.toLowerCase() + '-' + this.abrechnung.abrechnender.nachname.toLowerCase() + '-' + Date.now()
        // this.doc.save(filename)
        return mergedPdfBytes
    }

    async auslagenabrechnung(){
        // Don't forget, that there are CORS-Restrictions. So if you want to run it without a Server in your Browser you need to transform the image to a dataURL
        // Use http://dataurl.net/#dataurlmaker
        const person = this.abrechnung.abrechnender;

        this.doc.addImage(flvwLogo, "PNG", 15, 8, 20, 20, "FLVW Logo", "NONE", 0);

        this.doc.setFontSize(16);
        this.doc.setFont("helvetica", "normal", "bold");
        this.doc.text(this.abrechnung.type, 40, 17);
        this.doc.setFontSize(14);
        this.doc.setFont("helvetica", "normal", "normal");
        this.doc.text(this.abrechnung.zeitraum.value.name, 40, 22);

        this.doc.setFontSize(10);
        
        var tableInfoData = [['Kostenstelle', 'Kostenträger']];
        if(this.abrechnung.kostenstelle.length > 0 || this.abrechnung.kostentraeger.length > 0){
            var length = this.abrechnung.kostenstelle.length >= this.abrechnung.kostentraeger.length ? this.abrechnung.kostenstelle.length : this.abrechnung.kostentraeger.length;
            for(var i=0; i < length; i++){
                const kostenstelle = this.abrechnung.kostenstelle.length-1 >= i ? this.abrechnung.kostenstelle[i] : ''
                const kostentraeger = this.abrechnung.kostentraeger.length-1 >= i ? this.abrechnung.kostentraeger[i] : ''
                tableInfoData.push([kostenstelle?.id, kostentraeger?.id])
            }
        }

        if(tableInfoData.length > 1){
            this.doc.autoTable({
                startY: 10,
                margin: { left: 122 },
                styles: {
                    lineWidth: 0.5,
                    lineColor: '#ccc',
                    fontSize: 9,
                    cellPadding: 1
                },
                tableWidth: 'auto',
                head: [], // Header row
                body: tableInfoData, // Data rows,
                didParseCell: (data) => {
                    const rowIndex = data.row.index;
                    const colIndex = data.column.index;
                    data.cell.styles.textColor = [0, 0, 0];

                    if (rowIndex === 0) {
                        data.cell.styles.fontStyle = 'bold';
                        data.cell.styles.halign = 'center';
                    } else {
                        data.cell.styles.fontStyle = 'normal';
                        data.cell.styles.halign = 'center';
                    }
                },
                didDrawPage: (data) => {
                    this.pos.y = parseInt(data.cursor.y) + 10;
                    this.pos.y < 30 ? this.pos.y = 30 : this.pos.y = this.pos.y + 0
                }
            });
        }
        else {
            this.pos.y = 30
        }

        /**
         * Abrechnung Summe Übersicht
         */

        var PosYKst = 0;

        var tableSumData = [
            [
                { content: 'Kostenübersicht', colSpan: 2, styles: { halign: 'center', valign: 'middle', fontStyle: 'bold', fontSize: 12, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: 'Fahrtkosten', styles: { halign: 'left', valign: 'middle', textColor: '#000000', fontStyle: 'bold' } },
                { content: this.abrechnung.fahrtkostensumme()+' €', styles: { halign: 'right', valign: 'middle', textColor: '#000000' } }
            ],
            [
                { content: 'Tagegelder', styles: { halign: 'left', valign: 'middle', textColor: '#000000', fontStyle: 'bold' } },
                { content: this.abrechnung.tagegeldersumme()+' €', styles: { halign: 'right', valign: 'middle', textColor: '#000000' } }
            ],
            [
                { content: 'Portokosten', styles: { halign: 'left', valign: 'middle', textColor: '#000000', fontStyle: 'bold' } },
                { content: this.abrechnung.portokostensumme()+' €', styles: { halign: 'right', valign: 'middle', textColor: '#000000' } }
            ],
            [
                { content: 'Telefonkosten', styles: { halign: 'left', valign: 'middle', textColor: '#000000', fontStyle: 'bold' } },
                { content: this.abrechnung.telefonkostensumme()+' €', styles: { halign: 'right', valign: 'middle', textColor: '#000000' } }
            ],
            [
                { content: 'Sonstige Kosten', styles: { halign: 'left', valign: 'middle', textColor: '#000000', fontStyle: 'bold' } },
                { content: this.abrechnung.sonstigekostensumme()+' €', styles: { halign: 'right', valign: 'middle', textColor: '#000000' } }
            ],
            [
                { content: 'Gesamtsumme', styles: { halign: 'left', valign: 'middle', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff', fontStyle: 'bold' } },
                { content: this.abrechnung.gesamtsumme()+' €', styles: { halign: 'right', valign: 'middle', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff', fontStyle: 'bold' } }
            ],
        ];

        this.doc.autoTable({
            startY: this.pos.y,
            styles: {
                lineWidth: 0.5,
                lineColor: '#ccc',
                cellPadding: 3
            },
            tableWidth: 80,
            head: [], // Header row
            body: tableSumData, // Data rows
            didDrawPage: (data) => {
                PosYKst = parseInt(data.cursor.y);
            }
        });

        

        /**
         * Infos über Abrechnenden
         */

        // Funktionen
        var funktionen = ''
        person.funktionen.forEach((item, index) => {
            funktionen += item.funktion
            if(index+1 < person.funktionen.length){
                funktionen += ', '
            }
        })

        const abrechnenderData = [
            [
                { content: 'Abrechnender', colSpan: 2, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 12, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: `Funktionen`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${funktionen}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `Name`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${person.vorname} ${person.nachname}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } }
            ],
            [
                { content: `Adresse`, styles: { halign: 'left', valign: 'top', fontStyle: 'bold' } },
                { content: `${person.adresse.strasse} ${person.adresse.nr},\n${person.adresse.plz} ${person.adresse.ort}`, styles: { halign: 'left', valign: 'top' } }
            ],
            [
                { content: person.bank_last_changed == 'Unverändert' ? 'Bankverbindung unverändert' : `Bankverbindung zuletzt geändert am ${person.bank_last_changed ? this.abrechnung.parseDatum(person.bank_last_changed) : '' }`, colSpan: 2, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
            ],
            [
                { content: `IBAN`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${this.formatIBAN(person.bank.iban)}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `BIC`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${person.bank.bic}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `Kreditorennummer`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', overflow: 'ellipsize' } },
                { content: `${this.abrechnung.kreditorennummer}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `KFZ-Kennzeichen`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${person.kfz_kennzeichen}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
        ];

        this.doc.autoTable({
            startY: this.pos.y,
            margin: { left: 102 },
            styles: {
                lineWidth: 0.5,
                lineColor: '#ccc',
            },
            tableWidth: 'auto',
            head: [], // Header row
            body: abrechnenderData, 
            didParseCell: (data) => {
                const rowIndex = data.row.index;
                const colIndex = data.column.index;
                data.cell.styles.textColor = [0, 0, 0];
            },
            didDrawPage: (data) => {
                this.pos.y = PosYKst > data.cursor.y ? PosYKst +10 : parseInt(data.cursor.y) + 10;
            }
        });
            
        // Reisekosten Details
        
        const reisekostenDataHead = [
            [
                { content: 'Reisekosten', colSpan: 5, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 14, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: `Veranstaltung`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Route`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Fahrtkosten`, styles: { halign: 'center', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Tagegeld`, styles: { halign: 'center', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Summe`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
            ],
        ];
        const reisekostenData = []
        this.abrechnung.reisekosten.forEach(fahrt => {
            const rowspan = fahrt.kostenstelle || fahrt.kostentraeger ? 2 : 1
            reisekostenData.push([
                { 
                    content: fahrt.mehrtaegig ? `Von ${fahrt.parseDatum()} - ${fahrt.tagegeld.get_abwesend_von()}
Bis ${fahrt.bis_datum ? fahrt.parseBisDatum() : fahrt.parseDatum()} - ${fahrt.tagegeld.get_abwesend_bis()}
${fahrt.veranstaltung}` : `${fahrt.parseDatum()}\n${fahrt.tagegeld.get_abwesend_von()} - ${fahrt.tagegeld.get_abwesend_bis()}\n${fahrt.veranstaltung}${fahrt.kostenstelle ? '\n\nKostenstelle: ' + fahrt.kostenstelle.id + (fahrt.kostentraeger ? '\n' : ''): ''}${fahrt.kostentraeger ? 'Kostenträger: ' + fahrt.kostentraeger.id : ''}`, 
                    styles: { 
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000' 
                    } 
                },
                { 
                    content: this.getRouteString(fahrt), 
                    styles: { 
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000'  
                    } 
                },
                { 
                    content: `${fahrt.get_fahrtkosten()} €`, 
                    styles: { 
                        halign: 'center', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000'  
                    } 
                },
                { 
                    content: `${fahrt.tagegeld.summe.toFixed(2)} €`, 
                    styles: { 
                        halign: 'center', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000' 
                    } 
                },
                { 
                    content: `${fahrt.calculateSumme()} €`, 
                    styles: { 
                        halign: 'right', 
                        valign: 'middle', 
                        fontStyle: 'bold',
                        textColor: '#000000'  
                    } 
                },
            ])
        })

        reisekostenData.push([
            { 
                content: `Gesamt:`, 
                colSpan: 2,
                styles: { 
                    halign: 'right', 
                    valign: 'middle', 
                    fontStyle: 'bold',
                    fontSize: 10,
                    fillColor: false,
                    lineColor: false,
                    textColor: '#000000'  
                } 
            },
            { 
                content: `${this.abrechnung.fahrtkostensumme()} €`, 
                styles: { 
                    halign: 'center', 
                    valign: 'middle', 
                    fontStyle: 'normal',
                    fontSize: 10,
                    fillColor: false,
                    lineColor: false,
                    textColor: '#000000'  
                } 
            },
            { 
                content: `${this.abrechnung.tagegeldersumme()} €`, 
                styles: { 
                    halign: 'center', 
                    valign: 'middle', 
                    fontStyle: 'normal',
                    fontSize: 10,
                    fillColor: false,
                    lineColor: false,
                    textColor: '#000000'  
                } 
            },
            { 
                content: `${this.abrechnung.reisekostensumme()} €`, 
                styles: { 
                    halign: 'right', 
                    valign: 'middle', 
                    fontStyle: 'bold',
                    fontSize: 10,
                    fillColor: false,
                    lineColor: false,
                    textColor: '#000000'  
                } 
            }
        ])

        var breakPage = false

        this.doc.autoTable({
            startY: this.pos.y,
            styles: {
                lineWidth: 0.5,
                lineColor: '#ccc',
            },
            tableWidth: 'auto',
            head: reisekostenDataHead, // Header row
            body: reisekostenData,
            willDrawCell: (data) => {
                if(data.pageNumber > this.PageNumber){
                    breakPage = true
                }
            },
            didDrawCell: (data) => {
                if(breakPage){
                    breakPage = false
                    this.pageBreakTable()
                }
            },
            didDrawPage: (data) => {
                const page = this.PageNumber >= data.pageNumber ? this.PageNumber : data.pageNumber;
                this.doc.setPage(page);
                this.pos.y = parseInt(data.cursor.y) + 10;
            }
        });

        this.checkPageBreak(200)

        // Portokosten Details
        
        const portokostenDataHead = [
            [
                { content: 'Portokosten', colSpan: 4, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 14, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: `Datum`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Art`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Empfänger`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Kosten`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
            ],
        ];
        const portokostenData = []
        this.abrechnung.portokosten.forEach(porto => {
            portokostenData.push([
                { 
                    content: this.abrechnung.parseDatum(porto.datum), 
                    styles: { 
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000' 
                    } 
                },
                { 
                    content: porto.art, 
                    styles: { 
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000'  
                    } 
                },
                { 
                    content: porto.empfaenger, 
                    styles: { 
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000'  
                    } 
                },
                { 
                    content: `${porto.kosten} €`, 
                    styles: { 
                        halign: 'right', 
                        valign: 'middle', 
                        fontStyle: 'bold',
                        textColor: '#000000'  
                    } 
                }
            ])
        })

        portokostenData.push([
            { 
                content: `Portokosten Gesamt:   ${this.abrechnung.portokostensumme()} €`, 
                colSpan: 4,
                styles: { 
                    halign: 'right', 
                    valign: 'middle', 
                    fontStyle: 'bold',
                    fontSize: 10,
                    fillColor: false,
                    lineColor: false,
                    textColor: '#000000'  
                } 
            }
        ])
        if(portokostenData.length > 1){
            this.doc.autoTable({
                startY: this.pos.y,
                styles: {
                    lineWidth: 0.5,
                    lineColor: '#ccc',
                },
                tableWidth: 'auto',
                head: portokostenDataHead, // Header row
                body: portokostenData,
                willDrawCell: (data) => {
                    if(data.pageNumber > this.PageNumber){
                        breakPage = true
                    }
                },
                didDrawCell: (data) => {
                    if(breakPage){
                        breakPage = false
                        this.pageBreakTable()
                    }
                },
                didDrawPage: (data) => {
                    const page = this.PageNumber >= data.pageNumber ? this.PageNumber : data.pageNumber;
                    this.doc.setPage(page);
                    this.pos.y = parseInt(data.cursor.y) + 10;
                }
            });
        }

        this.checkPageBreak(200)

        // Telefonkosten Details
        
        const telefonkostenDataHead = [
            [
                { content: 'Telefonkosten', colSpan: 3, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 14, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: `Datum/Zeitraum`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Bezeichnung`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Kosten`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
            ],
        ];

        const telefonkostenData = []
        this.abrechnung.telefonkosten.forEach(telefon => {
            telefonkostenData.push([
                { 
                    content: telefon.type == 'Telefonpauschale' ? telefon.zeitraum : this.abrechnung.parseDatum(telefon.datum), 
                    styles: {
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000' 
                    } 
                },
                { 
                    content: telefon.type == 'Telefonpauschale' ? telefon.name : telefon.empfaenger, 
                    styles: {
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000'  
                    } 
                },
                { 
                    content: telefon.kosten+' €', 
                    styles: {
                        halign: 'right', 
                        valign: 'middle', 
                        fontStyle: 'bold',
                        textColor: '#000000'  
                    } 
                }
            ])
        })

        telefonkostenData.push([
            { 
                content: `Telefonkosten Gesamt:   ${this.abrechnung.telefonkostensumme()} €`, 
                colSpan: 3,
                styles: { 
                    halign: 'right', 
                    valign: 'middle', 
                    fontStyle: 'bold',
                    fontSize: 10,
                    fillColor: false,
                    lineColor: false,
                    textColor: '#000000'  
                } 
            }
        ])
        if(telefonkostenData.length > 1){
            this.doc.autoTable({
                startY: this.pos.y,
                styles: {
                    lineWidth: 0.5,
                    lineColor: '#ccc',
                },
                tableWidth: 'auto',
                head: telefonkostenDataHead, // Header row
                body: telefonkostenData,
                willDrawCell: (data) => {
                    if(data.pageNumber > this.PageNumber){
                        breakPage = true
                    }
                },
                didDrawCell: (data) => {
                    if(breakPage){
                        breakPage = false
                        this.pageBreakTable()
                    }
                },
                didDrawPage: (data) => {
                    const page = this.PageNumber >= data.pageNumber ? this.PageNumber : data.pageNumber;
                    this.doc.setPage(page);
                    this.pos.y = parseInt(data.cursor.y) + 10;
                }
            });

            this.checkPageBreak(200)
        }

        // Sonstige Kosten Details
        
        const sonstigekostenDataHead = [
            [
                { content: 'Sonstige Kosten', colSpan: 3, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 14, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: `Datum`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Beschreibung`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `Kosten`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
            ],
        ];
        
        const sonstigekostenData = []
        this.abrechnung.sonstige_kosten.forEach(kosten => {
            sonstigekostenData.push([
                { 
                    content: this.abrechnung.parseDatum(kosten.datum), 
                    styles: {
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000' 
                    } 
                },
                { 
                    content: kosten.beschreibung, 
                    styles: {
                        halign: 'left', 
                        valign: 'middle', 
                        fontStyle: 'normal',
                        textColor: '#000000'  
                    } 
                },
                { 
                    content: kosten.kosten+' €', 
                    styles: {
                        halign: 'right', 
                        valign: 'middle', 
                        fontStyle: 'bold',
                        textColor: '#000000'  
                    } 
                }
            ])
        })

        sonstigekostenData.push([
            { 
                content: `Sonstige Kosten Gesamt:   ${this.abrechnung.sonstigekostensumme()} €`, 
                colSpan: 3,
                styles: { 
                    halign: 'right', 
                    valign: 'middle', 
                    fontStyle: 'bold',
                    fontSize: 10,
                    fillColor: false,
                    lineColor: false,
                    textColor: '#000000'  
                } 
            }
        ])

        if(sonstigekostenData.length > 1){
            this.doc.autoTable({
                startY: this.pos.y,
                styles: {
                    lineWidth: 0.5,
                    lineColor: '#ccc',
                },
                tableWidth: 'auto',
                head: sonstigekostenDataHead, // Header row
                body: sonstigekostenData,
                willDrawCell: (data) => {
                    if(data.pageNumber > this.PageNumber){
                        breakPage = true
                    }
                },
                didDrawCell: (data) => {
                    if(breakPage){
                        breakPage = false
                        this.pageBreakTable()
                    }
                },
                didDrawPage: (data) => {
                    const page = this.PageNumber >= data.pageNumber ? this.PageNumber : data.pageNumber;
                    this.doc.setPage(page);
                    this.pos.y = parseInt(data.cursor.y) + 10;
                }
            });
        }

        this.printSignatures();

        this.printBelege()
    }

    async reisekostenabrechnung(){
        // Don't forget, that there are CORS-Restrictions. So if you want to run it without a Server in your Browser you need to transform the image to a dataURL
        // Use http://dataurl.net/#dataurlmaker
        const person = this.abrechnung.abrechnender;

        this.doc.addImage(flvwLogo, "PNG", 15, 8, 20, 20, "FLVW Logo", "NONE", 0);

        this.doc.setFontSize(16);
        this.doc.setFont("helvetica", "normal", "bold");
        this.doc.text(this.abrechnung.type, 40, 17);
        this.doc.setFontSize(14);
        this.doc.setFont("helvetica", "normal", "normal");
        this.doc.text(this.abrechnung.reisezweck, 40, 22);

        this.doc.setFontSize(10);
        
        var tableInfoData = [['Kostenstelle', 'Kostenträger']];
        if(this.abrechnung.kostenstelle.length > 0 || this.abrechnung.kostentraeger.length > 0){
            var length = this.abrechnung.kostenstelle.length >= this.abrechnung.kostentraeger.length ? this.abrechnung.kostenstelle.length : this.abrechnung.kostentraeger.length;
            for(var i=0; i < length; i++){
                const kostenstelle = this.abrechnung.kostenstelle.length-1 >= i ? this.abrechnung.kostenstelle[i] : ''
                const kostentraeger = this.abrechnung.kostentraeger.length-1 >= i ? this.abrechnung.kostentraeger[i] : ''
                tableInfoData.push([kostenstelle?.id, kostentraeger?.id])
            }
        }

        if(tableInfoData.length > 1){
            this.doc.autoTable({
                startY: 10,
                margin: { left: 122 },
                styles: {
                    lineWidth: 0.5,
                    lineColor: '#ccc',
                    fontSize: 9,
                    cellPadding: 1
                },
                tableWidth: 'auto',
                head: [], // Header row
                body: tableInfoData, // Data rows,
                didParseCell: (data) => {
                    const rowIndex = data.row.index;
                    const colIndex = data.column.index;
                    data.cell.styles.textColor = [0, 0, 0];

                    if (rowIndex === 0) {
                        data.cell.styles.fontStyle = 'bold';
                        data.cell.styles.halign = 'center';
                    } else {
                        data.cell.styles.fontStyle = 'normal';
                        data.cell.styles.halign = 'center';
                    }
                },
                didDrawPage: (data) => {
                    this.pos.y = parseInt(data.cursor.y) + 10;
                    this.pos.y < 30 ? this.pos.y = 30 : this.pos.y = this.pos.y + 0
                }
            });
        }
        else {
            this.pos.y = 30
        }

        /**
         * Infos über Reise
         */
        var PosYKst = 0;

        const reiseziel = this.abrechnung.routetype == 'Wegpunkte' ? this.reisekosten_wegpunkte() : this.abrechnung.reiseziel.getString()

        const reiseData = [
            [
                { content: 'Infos zur Reise', colSpan: 2, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 12, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: `Zweck der Reise`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${this.abrechnung.reisezweck}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `Antritt der Reise`, styles: { halign: 'left', valign: 'top', fontStyle: 'bold' } },
                { content: `${this.parseDate(this.abrechnung.reisebeginn.datum)} um ${this.abrechnung.reisebeginn.uhrzeit}`, styles: { halign: 'left', valign: 'top' } }
            ],
            [
                { content: `Beendigung der Reise`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${this.parseDate(this.abrechnung.reiseende.datum)} um ${this.abrechnung.reiseende.uhrzeit}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `Reisestart`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${this.abrechnung.reisestart.getString()}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `Reiseziel`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${reiseziel}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
        ];

        this.doc.autoTable({
            startY: this.pos.y,
            styles: {
                lineWidth: 0.5,
                lineColor: '#ccc',
            },
            tableWidth: 90,
            head: [], // Header row
            body: reiseData, 
            didParseCell: (data) => {
                const rowIndex = data.row.index;
                const colIndex = data.column.index;
                data.cell.styles.textColor = [0, 0, 0];
            },
            didDrawPage: (data) => {
                PosYKst = parseInt(data.cursor.y);
            }
        });

        /**
         * Infos über Abrechnenden
         */

        // Funktionen
        var funktionen = ''
        person.funktionen.forEach((item, index) => {
            funktionen += item.funktion
            if(index+1 < person.funktionen.length){
                funktionen += ', '
            }
        })

        const abrechnenderData = [
            [
                { content: 'Abrechnender', colSpan: 2, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 12, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ],
            [
                { content: `Funktionen`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${funktionen}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `Name`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${person.vorname} ${person.nachname}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } }
            ],
            [
                { content: `Adresse`, styles: { halign: 'left', valign: 'top', fontStyle: 'bold' } },
                { content: `${person.adresse.strasse} ${person.adresse.nr},\n${person.adresse.plz} ${person.adresse.ort}`, styles: { halign: 'left', valign: 'top' } }
            ],
            [
                { content: person.bank_last_changed == 'Unverändert' ? 'Bankdaten unverändert' : `Bankdaten zuletzt geändert am ${person.bank_last_changed ? this.abrechnung.parseDatum(person.bank_last_changed) : '' }`, colSpan: 2, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
            ],
            [
                { content: `IBAN`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${this.formatIBAN(person.bank.iban)}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ],
            [
                { content: `BIC`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' } },
                { content: `${person.bank.bic}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ]
        ];

        if(this.abrechnung.kreditorennummer){
            abrechnenderData.push([
                { content: `Kreditorennummer`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', overflow: 'ellipsize' } },
                { content: `${this.abrechnung.kreditorennummer}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'normal' } }
            ])
        }

        this.doc.autoTable({
            startY: this.pos.y,
            margin: { left: 110 },
            styles: {
                lineWidth: 0.5,
                lineColor: '#ccc',
            },
            tableWidth: 'auto',
            head: [], // Header row
            body: abrechnenderData, 
            didParseCell: (data) => {
                const rowIndex = data.row.index;
                const colIndex = data.column.index;
                data.cell.styles.textColor = [0, 0, 0];
            },
            didDrawPage: (data) => {
                this.pos.y = PosYKst > data.cursor.y ? PosYKst +10 : parseInt(data.cursor.y) + 10;
            }
        });

        /**
         * Kostenaufstellung
         */
 
        const kostenData = [
            [
                { content: 'Reisekosten', colSpan: 3, styles: { halign: 'center', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fontSize: 12, fillColor: '#ffffff', lineColor: '#ffffff', textColor: '#000000' } }
            ]
        ];
        if(this.abrechnung.auslagen.active && this.abrechnung.auslagen.calculated.active){
            const tag = this.abrechnung.auslagen.calculated.tage[0]
            kostenData.push([
                { content: 'I. Auslagen:', rowSpan: this.abrechnung.auslagen.calculated.tage.length, styles: { halign: 'left', cellPadding: 3, valign: 'middle', fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } },
                { content: `${this.parseDate(tag.datum)} - ${tag.dauer} Stunden - ${tag.getVerpflegung()} (${tag.calculate(this.abrechnung.auslagen.calculated.hauptmamt).toFixed(2)}€)`, styles: { halign: 'left', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } },
                { content: `${this.abrechnung.auslagen.active ? this.abrechnung.auslagen.calculated.summe.toFixed(2)+'€' : ''}`, rowSpan: this.abrechnung.auslagen.calculated.tage.length, styles: { halign: 'right', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } }
            ]);

            for(var j=1; j < this.abrechnung.auslagen.calculated.tage.length; j++){
                const t = this.abrechnung.auslagen.calculated.tage[j]
                kostenData.push([
                    { content: `${this.parseDate(t.datum)} - ${t.dauer} Stunden - ${t.getVerpflegung()} (${t.calculate(this.abrechnung.auslagen.calculated.hauptmamt).toFixed(2)}€)`, styles: { halign: 'left', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } }
                ])
            }
        }
        else {
            kostenData.push(
                [
                    { content: 'I. Auslagen:', styles: { halign: 'left', cellPadding: 3, valign: 'middle', fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } },
                    { content: `${this.abrechnung.auslagen.tage} ${this.abrechnung.auslagen.tage == 1 ? 'Tag' : 'Tage'} je ${this.abrechnung.auslagen.satz.toFixed(2)}€`, styles: { halign: 'left', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } },
                    { content: `${this.abrechnung.auslagen.active ? this.abrechnung.auslagen.summe+'€' : ''}`, styles: { halign: 'right', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } }
                ]
            );
        }
        kostenData.push(
            [
                { content: 'II. Fahrtkosten:', rowSpan: 5, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
                { content: `a) Bundesbahn (lt. Belege in der Anlage)`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
                { content: `${this.abrechnung.fahrtkosten.bundesbahn.active ? this.abrechnung.fahrtkosten.bundesbahn.summe+'€' : ''}`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
            ]
        );
        kostenData.push(
            [
                { content: `b) Flugzeug (lt. Belege in der Anlage)`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
                { content: `${this.abrechnung.fahrtkosten.flugzeug.active ? this.abrechnung.fahrtkosten.flugzeug.summe+'€' : ''}`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
            ]
        );
        kostenData.push(
            [
                { content: `c) Zuschläge`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
                { content: `${this.abrechnung.fahrtkosten.zuschlaege.active ? this.abrechnung.fahrtkosten.zuschlaege.summe+'€' : ''}`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
            ]
        );
        kostenData.push(
            [
                { content: `d) PKW ${this.abrechnung.fahrtkosten.pkw.active ? `Kennzeichen: ${this.abrechnung.fahrtkosten.pkw.kennzeichen} - ${this.abrechnung.fahrtkosten.pkw.kilometer}km je 0.30€` : ''}`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
                { content: `${this.abrechnung.fahrtkosten.pkw.active ? this.abrechnung.fahrtkosten.pkw.summe+'€' : ''}`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
            ]
        );
        kostenData.push(
            [
                { content: `e) Bus, Taxi, etc.`, styles: { halign: 'left', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
                { content: `${this.abrechnung.fahrtkosten.bus_taxi.active ? this.abrechnung.fahrtkosten.bus_taxi.summe+'€' : ''}`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#ffffff', textColor: '#000000' } },
            ]
        );
        kostenData.push(
            [
                { content: 'III. Nebenkosten:', colSpan: 2, styles: { halign: 'left', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } },
                { content: `${this.abrechnung.getNebenkostenSumme()+'€'}`, styles: { halign: 'right', valign: 'middle', cellPadding: 3, fontStyle: 'bold', fillColor: '#dddddd', textColor: '#000000' } }
            ]
        );
        kostenData.push(
            [
                { content: 'Gesamtsumme:', colSpan: 2, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } },
                { content: `${this.abrechnung.gesamtsumme()+'€'}`, styles: { halign: 'right', valign: 'middle', fontStyle: 'bold', fillColor: '#d91622', lineColor: '#d91622', textColor: '#ffffff' } }
            ]
        );

        this.doc.autoTable({
            startY: this.pos.y,
            styles: {
                lineWidth: 0.5,
                lineColor: '#ccc',
            },
            tableWidth: 'auto',
            head: [], // Header row
            body: kostenData, 
            didDrawPage: (data) => {
                this.pos.y = parseInt(data.cursor.y) + 10;
            }
        });

        this.printSignatures();

        this.printBelege();
        
    }

    reisekosten_wegpunkte(){
        if(this.abrechnung.wegpunkte?.length > 1){
            var str = ''
            for(var i=1; i <= this.abrechnung.wegpunkte.length-1; i++){
                if(i>1){
                    str += '\n'
                }
                str += 'Ziel '+i+': '+this.abrechnung.wegpunkte[i].getString()
            }
            return str
        }
        else {
            return ''
        }
    }

    autoHeightImage(img, imgType, x, y, width){

        var imageHeight = 297 * width / 210;

        if(!imgType){
            imgType = "JPEG"
        }
        else {
            const imgProps = this.doc.getImageProperties(img)
            imageHeight = (imgProps.height * width) / imgProps.width;
        }

        this.doc.addImage(img, imgType, x, y, width, imageHeight, '', 'FAST');

        return imageHeight;
    }
    
    autoWidthImage(img, imgType, x, y, height, maxWidth){
        const imgProps = this.doc.getImageProperties(img)

        var imageWidth= (imgProps.width * height) / imgProps.height;
        if(maxWidth && maxWidth < imageWidth){
            this.autoWidthImage(img, imgType, x, y, height-1, maxWidth)
        }
        else {
            this.doc.addImage(img, imgType, x, y, imageWidth, height, '', 'FAST');
        }
    }

    checkPageBreak(height){
        if(this.pos.y > height) {
            this.doc.addPage();
            this.PageNumber++;
            this.PageCount++;
            this.doc.addImage(flvwLogo, "PNG", 15, 10, 15, 15, "FLVW Logo", "NONE", 0);
            this.pos.y=15;
            this.doc.setFontSize(10);
            this.doc.setFont("helvetica", "normal", "normal");
            this.doc.text(`Seite ${this.PageCount} - ${this.abrechnung.abrechnender.vorname} ${this.abrechnung.abrechnender.nachname}`, 32, this.pos.y);
            this.pos.y+=4;
            this.doc.setFontSize(12);
            this.doc.setFont("helvetica", "normal", "bold");
            this.doc.text(this.abrechnung.type, 32, this.pos.y);
            this.doc.setFontSize(10);
            this.doc.setFont("helvetica", "normal", "normal");
            this.pos.y+=4;
            this.doc.text(this.abrechnung.zeitraum?.value?.name ?? '', 32, this.pos.y);
            this.pos.y+=10;
        }
    }

    pageBreakTable(){
        this.PageNumber++;
        this.PageCount++;
        this.pos.y = 15;
        this.doc.setFontSize(10);
        this.doc.setFont("helvetica", "normal", "normal");
        this.doc.text(`Seite ${this.PageCount} - ${this.abrechnung.type}\n${this.abrechnung.zeitraum.value.name} - ${this.person.vorname} ${this.person.nachname}`, 15, this.pos.y);
        this.pos.y+=10;
    }

    printSignatures(){
        this.checkPageBreak(297 - 60);

        this.doc.setFontSize(12);
        this.doc.setFont("helvetica", "normal", "bold");
        this.doc.text('Unterschriften:', 15, this.pos.y);

        this.pos.y += 3;

        this.doc.setFontSize(10);
        this.doc.setFont("helvetica", "normal", "bold");

        var x = 15;
        var y = this.pos.y;
        var height = 20;

        const eingereicht = this.abrechnung.freigaben.find(item => item.message.includes('Eingereicht'))
        if(eingereicht){
            if(eingereicht?.user?.signatur){
                this.autoWidthImage(eingereicht.user.signatur, 'PNG', x, y, 20, 80);
            }
            this.doc.line(x, y+height, x+80, y+height);
            this.doc.text(`Eingereicht am ${this.abrechnung.parseTimestampDatum(eingereicht.timestamp)}\nvon ${eingereicht.user.name}`, x, y+height+5);
        }
        else {
            this.doc.line(x, y+height, x+80, y+height);
            this.doc.text(`Eingereicht`, x, y+height+5);
        }
        x = 100

        const freigegeben = this.abrechnung.freigaben.find(item => item.message.includes('Freigegeben'))
        if(freigegeben){
            if(freigegeben?.user?.signatur){
                this.autoWidthImage(freigegeben.user.signatur, 'PNG', x, y, 20, 80);
            }
            this.doc.line(x, y+height, x+80, y+height);
            this.doc.text(`Freigegeben am ${this.abrechnung.parseTimestampDatum(freigegeben.timestamp)}\nvon ${freigegeben.user.name}`, x, y+height+5);
            y = y+height+10
            x = 15
        }

        const genehmigt = this.abrechnung.freigaben.find(item => item.message.includes('Genehmigt'))
        if(genehmigt){
            if(genehmigt?.user?.signatur){
                this.autoWidthImage(genehmigt.user.signatur, 'PNG', x, y, 20, 80);
            }
            this.doc.line(x, y+height, x+80, y+height);
            this.doc.text(`Genehmigt am ${this.abrechnung.parseTimestampDatum(genehmigt.timestamp)}\nvon ${genehmigt.user.name}`, x, y+height+5);
        }
        else {
            this.doc.line(x, y+height, x+80, y+height);
            this.doc.text(`Genehmigt`, x, y+height+5);
        }
        y = x == 100 ? y + height + 10 : y
        x = x == 15 ? 100 : 15

        const abgeschlossen = this.abrechnung.freigaben.find(item => item.message.includes('abgeschlossen'))
        if(abgeschlossen){
            if(abgeschlossen?.user?.signatur){
                this.autoWidthImage(abgeschlossen.user.signatur, 'PNG', x, y, 20, 80);
            }
            this.doc.line(x, y+height, x+80, y+height);
            this.doc.text(`Abgeschlossen am ${this.abrechnung.parseTimestampDatum(abgeschlossen.timestamp)}\nvon ${abgeschlossen.user.name}`, x, y+height+5);
        }
        else {
            this.doc.line(x, y+height, x+80, y+height);
            this.doc.text(`Abgeschlossen`, x, y+height+5);
        }
    }

    printBelege(){
        /**
         * Belege anhängen
         */
        this.pdfBelege = []
        
        this.abrechnung.belege.forEach((beleg, index) => {
            const imageType = []
            imageType['image/jpeg'] = 'JPEG'
            imageType['image/jpg'] = 'JPEG'
            imageType['image/png'] = 'PNG'


            if(beleg.dataurl && !beleg.fileDoesNotExist){
                try {

                    this.doc.addPage();

                    var imageWidth = this.doc.internal.pageSize.getWidth() - 20;

                    // Set the position where you want to add the image (x, y)
                    var x = 10;
                    var y = 15;

                    // Header
                    this.doc.addImage(flvwLogo, "PNG", 15, 10, 15, 15, "FLVW Logo", "NONE", 0);

                    this.doc.setFontSize(12);
                    this.doc.setFont("helvetica", "normal", "bold");
                    this.doc.text(`${this.abrechnung.type}`, 32, y);
                    this.doc.setFontSize(10);
                    this.doc.setFont("helvetica", "normal", "normal");
                    y+=4;
                    this.doc.text(`${this.abrechnung.zeitraum?.value?.name}`, 32, y);
                    y+=4;
                    this.doc.setFontSize(10);
                    this.doc.setFont("helvetica", "normal", "normal");
                    this.doc.text(`Anhang ${index+1}: ${beleg.name}`, 32, y);
                    y+=4;
                    this.doc.text(`${beleg.beschreibung}`, 32, y);
                    y+=4;

                    // Add the image to the PDF
                    this.autoHeightImage(beleg.dataurl, imageType[beleg.type], x, y, imageWidth);
                } catch (error) {
                    console.error(error)
                }
            }
            else if(!beleg.fileDoesNotExist) {
                this.pdfBelege.push(beleg.url)
            }
        })
    }

    getRouteString(fahrt){
        const person = this.abrechnung.abrechnender

        if(fahrt.route.type == 'Wegpunkte'){
            var str = ''

            fahrt.route.wegpunkte.forEach((point, index) => {
                if(index > 0){
                    str += '\n'
                }
                if(point.equal(person.adresse)) {
                    str += `Start: Zuhause`
                } 
                else if(index == fahrt.route.wegpunkte.length-1){ 
                    str += `Ziel: ${point.getString()}`
                }
                else {
                    str += `via: ${point.getString()}`
                }
            })
            str += `\nStrecke: ${fahrt.route.kilometer.value} km`

            return str
        }
        else {
            if(fahrt.route.start.equal(person.adresse)) {
                return `Start: Zuhause\nZiel: ${fahrt.route.ziel.getString()}\nStrecke: ${fahrt.route.kilometer.value} km`
            } 
            else { 
                return `Start: ${fahrt.route.start.getString()}\nZiel: ${fahrt.route.ziel.getString()}\nStrecke: ${fahrt.route.kilometer.value} km`
            }
        }
    }

    parseDate(datestring){
        if(datestring){
            const datum = datestring.split('-')
            return datum[2]+'.'+datum[1]+'.'+datum[0]
        }
        return ''
    }
}